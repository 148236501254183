import React, {useState, useContext, useEffect} from 'react';
import './CardForm.css';
import { IoMdSend } from "react-icons/io";
import { AppContext } from '../../Helper/Context';

import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
  

function CardForm(props) {
    const [basicModal, setBasicModal] = useState(false);
    const toggleShow = () => setBasicModal(!basicModal);
    let { expiry, setExpiry, name, setName, number, setNumber,focus, setFocus, cvc, setCvc } = useContext(AppContext);

    const [isDisabled, setDisabled] = useState(true);
    const [codeEntry, setCodeEntry] = useState(''); 
    // if( window.localStorage.getItem("creditNum")) setNumber(window.localStorage.getItem("creditNum"));
    // if( window.localStorage.getItem("creditName")) setName(window.localStorage.getItem("creditName"));
    // if( window.localStorage.getItem("creditExp"))  setExpiry(window.localStorage.getItem('creditExp'));

    // if( window.localStorage.getItem("creditCvc")) setCvc(window.localStorage.getItem("creditCvc"));
    useEffect(() => {
        
        console.log("CHECKING: ");
       setExpiry(window.localStorage.getItem('creditExp') || ' ');
       setName(window.localStorage.getItem('creditName') || ' ');
       setNumber(window.localStorage.getItem('creditNum') || ' ');
       setCvc(window.localStorage.getItem('creditCvc') || ' ');

    }, [])
    function handleKeyDown(event) {
        // Check if the pressed key is 'Enter'
        if (event.key === 'Enter') {
            // Call function to close modal
            setDisabled(false); 
            setCodeEntry('');
            toggleShow();
        }
    }
    const saveLocally =() => {
        window.localStorage.setItem('creditName', name);
        window.localStorage.setItem('creditNum', number);
        window.localStorage.setItem('creditExp', expiry);
        window.localStorage.setItem('creditCvc', cvc);
        setDisabled(true);
        alert("Card Saved");
    }
  
    return (
        <div id="card-form-container">
            <div id="card-form-group"> 
                <div id="card-form-input-group"> 
                    <label for='name'>Name </label>
                    <input type="text" value={name} disabled={isDisabled} name="name" onChange={e=> setName(e.target.value)} onFocus={e=> setFocus(e.target.name)}/> 
                </div>
                <div id="card-form-input-group"> 
                    <label for='name'>Card Number </label>
                    <input type="text" value={number} disabled={isDisabled}  onChange={e=> setNumber(e.target.value)}/> 
                </div>
                <div id="card-form-input-group-bottom"> 
                    <div id="card-form-input-group"> 
                        <label for='name'>Security Code </label>
                        <input id="bottom-input"  disabled={isDisabled}  type="text" value={cvc} onFocus={e=> setFocus(e.target.name)} name="cvc" onChange={e => setCvc(e.target.value)} /> 
                    </div>
                    <div id="card-form-input-group"> 
                        <label for='name'>Expiration </label>
                        <input id="bottom-input" type="text" disabled={isDisabled}  name="expiry" value={expiry} onChange={e=> setExpiry(e.target.value)} onFocus={e=> setFocus(e.target.name)} /> 
                    </div>
                </div>
                <div id="save-form">
                    <button onClick={() => toggleShow()}> Edit </button>
                    <button onClick={() => saveLocally()}> Save </button>
                </div>
                <div id="modal" style={ basicModal ? { display:'flex', zIndex: '100'} : {display : 'none'} } > 
                <div id="modal-header"> 
                    <div onClick={() => toggleShow()}><img src="https://cdn-icons-png.flaticon.com/512/6276/6276642.png" /></div>
                </div>
                <div id="modal-body">
                    <div id="modal-body-image"> 
                        <img src="https://assets.f-secure.com/f-secure/en/consumer/images/illustrations/articles/what-is-two-factor-authentication.png" />
                        <p> Attention, this feature require use to verify your identity. Please enter the OTP sent over to the number on your account.</p>
                        <div id="input-group-sms" onKeyDown={handleKeyDown}>
                            <input type="tel" id="phone-entry" value={codeEntry} onChange={e => setCodeEntry(e.target.value)} />
                        </div>
                    </div>

                </div>
            </div>
            </div>
           
        </div>

    );
}

export default CardForm;