import React, { useState, createContext, useEffect } from 'react';
import './settings.css';
import BrandSettings from './BrandSettings/BrandSettings.js';
import ConfigSettings from './ConfigSettings/ConfigSettings.js';
// import {WebsiteUrlContext,  } from './Helper/Context';
import SessionController from '../../controllers/session';

function Settings(props) {
  (async () => {
    let sessionExists = await SessionController.checkSession();
  })();
  return (
    <div id="settingsContainer">
      <BrandSettings />
      <ConfigSettings />
    </div>
  );
}

export default Settings;
