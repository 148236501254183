import React, { useState, useContext } from 'react';
import Cards from 'react-credit-cards';
import { AppContext } from '../../Helper/Context';
import 'react-credit-cards/es/styles-compiled.css';
import './Card.css';

export default function PaymentForm() {
  let { expiry, setExpiry, name, setName, number, setNumber, cvc, setCvc, focus, setFocus } = useContext(AppContext);
//   let focu
//   const handleInputFocus = (e) => {
//     setFocus(e.target.name);
//   }


  

  return (
    <div id="PaymentForm">
      <Cards
        cvc={cvc}
        expiry={expiry}
        focused={focus}
        name={name}
        number={number}
      />
    </div>
  );
}
