import './finance.css';
import React, { useState } from 'react';
import axios from 'axios';
import auth0 from 'auth0-js';
import CookieManager from '../../helpers/CookieManager';
import config from '../../helpers/config';

const Finance = () => {
  let cookieManager = new CookieManager();

  let menu_element  = document.querySelector("#styled-burger");
  if(menu_element) {
    console.log("MENU_ELEMENT: ", menu_element);
    menu_element.style.animation = 'none';
    menu_element.style.opacity = ".07";
    menu_element.addEventListener("mouseover", () => {
      menu_element.style.opacity = '1';
    })
    menu_element.addEventListener("mouseout", () => {
      menu_element.style.opacity = '.07';
    })
  }

  let { domain, MDT_finance, app_domain, tenant, base_app_domain } = cookieManager.getCookies();

  if (!cookieManager.hasAllCookies()) {
    window.location.href = '/tenant';
  }

  const openUniversalLogin = async () => {
    let { get_access_token_url, update_brand_url } = config.mdt_vercel_backend;
    let get_at_options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ tenant }),
    };

    let get_at_res = await fetch(get_access_token_url, get_at_options);
    let { client_id, client_secret } = await get_at_res.json();

    let body = {
      domain,
      client_id,
      client_secret,
      brand: {
        background_image_url:
          'https://images.pond5.com/4k-loop-financial-chart-background-088038878_prevstill.jpeg',
        widget_background: '#FFFFFF',
        logo_url: 'https://www.silex-im.com/silex-im.png',
      },
    };

    window.localStorage.setItem('profile_pic', body.brand.logo_url);
    let response = await axios.post(update_brand_url, body).then((res) => {
      // console.log('response: ', res.data);
      return res.data;
    });

    let a0 = new auth0.WebAuth({
      domain,
      clientID: MDT_finance,
    });
    a0.authorize({
      redirectUri: `${app_domain}/profile/general`,
      responseType: 'id_token',
    });

    cookieManager.setLoggedInCookie(MDT_finance, base_app_domain);
  };
  return (
    <div className="finance">
      <div id="loginButton" onClick={openUniversalLogin}>
        {' '}
      </div>
      {/* <h1> Finance </h1>  */}
    </div>
  );
};

export default Finance;
