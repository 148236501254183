import React from 'react';
import './ecommerce.css';
import axios from 'axios';
import auth0 from 'auth0-js';
import CookieManager from '../../helpers/CookieManager';
import config from '../../helpers/config';

function Ecommerce(props) {
  let cookieManager = new CookieManager();


  let menu_element  = document.querySelector("#styled-burger");
  if(menu_element) {
    console.log("MENU_ELEMENT: ", menu_element);
    menu_element.style.animation = 'none';
    menu_element.style.opacity = ".07";
    menu_element.addEventListener("mouseover", () => {
      menu_element.style.opacity = '1';
    })
    menu_element.addEventListener("mouseout", () => {
      menu_element.style.opacity = '.07';
    })
  }
  if(!cookieManager.hasAllCookies()) {
    window.location.href = "/tenant"
  }

  let { domain, MDT_ecommerce, base_app_domain, app_domain, tenant } =
    cookieManager.getCookies();

  let { get_access_token_url, update_brand_url } = config.mdt_vercel_backend;

  const openUniversalLogin = async () => {
    let get_at_options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ tenant }),
    };

    let get_at_res = await fetch(get_access_token_url, get_at_options);
    let { client_id, client_secret } = await get_at_res.json();

    let body = {
      domain,
      client_id,
      client_secret,
      brand: {
        background_image_url:
          'https://d39l2hkdp2esp1.cloudfront.net/img/photo/168095/168095_00_2x.jpg',
        widget_background: '#FFFFFF',
        logo_url:
          'https://cdn.shopify.com/s/files/1/0171/0160/files/Troubadour_Logo_Black.png?v=1656656172',
      },
    };
    window.localStorage.setItem('profile_pic', body.brand.logo_url);
    let response = await axios.post(update_brand_url, body).then((res) => {
      // console.log('response: ', res.data);
      return res.data;
    });
    // console.log('RESPONSE: ', response);

    
    let a0 = new auth0.WebAuth({
      domain,
      clientID: MDT_ecommerce,
    });

    a0.authorize({
    redirectUri: `${app_domain}/profile/general`,
      responseType: 'token id_token',
    });
    
    cookieManager.setLoggedInCookie(MDT_ecommerce, base_app_domain);
  };
  return (
    <div className="ecommerce">
      <div id="loginButton" onClick={openUniversalLogin}>
        {' '}
      </div>
    </div>
  );
}

export default Ecommerce;
