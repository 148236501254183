import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useContext } from 'react';
import auth0 from 'auth0-js';
import { AiOutlineLogout } from 'react-icons/ai';
import { AppContext } from '../../Helper/Context';

import CookieController from '../../controllers/cookie';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
} from 'react-router-dom';
import './profile.css';

const Profile = () => {
  const { currentFixtures } = useContext(AppContext);

  console.log('LOGO CONTEXT: ', currentFixtures);
  let profile_pic_url = window.localStorage.getItem('profile_pic');
  let [logo_url, setLogoUrl] = useState(localStorage['logo_url']);
  console.log('PROFILE_PIC_URL: ', profile_pic_url);
  console.log('PROFILE_PIC_URL: ', logo_url);

  const { domain, defaultClientId } = CookieController.getAllCookies();

  const logout = async () => {
    clearLocalStorage();
    let a0 = new auth0.WebAuth({
      domain,
      clientID: defaultClientId,
    });
    a0.logout({
      returnTo: window.location.origin,
    });
  };

  // Clear session / localstorage
  const clearLocalStorage = () => {
    // Delete localStorage id_token_raw, access_token_raw, id_token_claims, access_token_claims, logged_in_user_id
    const info_to_delete = [
      'id_token_raw',
      'id_token_claims',
      'access_token_raw',
      'access_token_claims',
      'logged_in_user_id',
    ];

    for (let info of info_to_delete) {
      delete localStorage[info];
    }
  };

  const eles = [
    'General',
    'Account',
    'Token',
    'Appearance',
    'Documentation',
    'Logout',
  ];

  return (
    <div id="profile-container">
      <div id="top-navbar">
        <div className="top-nav-item"> Home </div>
        <div className="top-nav-item"> Account </div>
        <div
          className="top-nav-item"
          id="profile-logout-btn"
          onClick={() => logout()}
        >
          {' '}
          <AiOutlineLogout id="logout-icon" />{' '}
        </div>
      </div>
      <div id="left-navbar">
        <div id="business-logo">
          <img src={logo_url} />
        </div>
        <div id="left-nav-divs">
          <div>
            <Link
              to={`/profile/General`}
              className="element-link"
              id="general-profile-link"
            >
              <div id="left-child-div"> General </div>
            </Link>
            <Link
              to={`/profile/Account`}
              className="element-link"
              id="general-profile-link"
            >
              <div id="left-child-div"> Account </div>
            </Link>
            <Link
              to={`/profile/Token`}
              className="element-link"
              id="general-profile-link"
            >
              <div id="left-child-div"> Token </div>
            </Link>
            <Link to={`#`} className="element-link" id="general-profile-link">
              <div id="left-child-div"> Appearance </div>
            </Link>
            <Link to={`#`} className="element-link" id="general-profile-link">
              <div id="left-child-div"> Documentation </div>
            </Link>
            {/* <div id="left-child-div"  onClick={() => logout()}> Logout </div> */}
          </div>
        </div>
      </div>
      <div id="main-view">
        <Outlet />
      </div>
    </div>
  );
};

export default Profile;
