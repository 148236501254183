import React, { useState, useEffect } from 'react';
import './token.css';

function Token(props) {
  let idToken = window.localStorage.getItem('id_token_raw') || '';
  let idTokenClaims =
    window.localStorage.getItem('id_token_claims') ||
    JSON.stringify({ null: 'null' });

  let accessToken = window.localStorage.getItem('access_token_raw') || '';
  let accessTokenClaims =
    window.localStorage.getItem('access_token_claims') ||
    JSON.stringify({ null: 'null' });

  useEffect(() => {}, [idToken, accessToken]);

  return (
    <div id="tokenContainer">
      <div id="idTokenContainer">
        <div className="tokenDisplay">
          <textarea
            className="tokenTextArea"
            value={idToken}
            readonly
            spellCheck="false"
            disabled
          />
          <h3> Encoded ID Token </h3>
        </div>
        <div className="tokenExplaination">
          <pre className="tokenExplanationPre">
            {JSON.stringify(JSON.parse(idTokenClaims), null, 3)}
          </pre>
          <h3> Decoded ID Token </h3>
        </div>
      </div>
      <div id="accessTokenContainer">
        <div className="tokenDisplay">
          <textarea
            className="tokenTextArea"
            value={accessToken}
            readonly
            spellCheck="false"
            disabled
          />
          <h3> Encoded Access Token </h3>
        </div>
        <div className="tokenExplaination">
          <pre className="tokenExplanationPre">
            {JSON.stringify(JSON.parse(accessTokenClaims), null, 3)}
          </pre>
          <h3> Decoded Access Token </h3>
        </div>
      </div>
    </div>
  );
}

export default Token;
