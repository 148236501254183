import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import './ConfigSettings.css';
import { AppContext } from '../../../Helper/Context';
import localforage from 'localforage';

function ConfigSettings(props) {
  let {
    screenShotUrl,
    setScreenshotUrl,
    universalLoginImage,
    loadingUl,
    setLoadingUl,
    loadingSS,
    setLoadingSS,
  } = useContext(AppContext);
  const [selectedImage, setSelectedImage] = useState('');
  const fileInputRef = useRef(null);
  const [highlight, setHighlight] = useState(false);
  const onFileDrop = useCallback((event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    // Process files here
    // console.log('FILE::: ', files);
    openFile(files);
    setHighlight(false);
  }, []);
  let openFile = function (file) {
    var input = file.target;
    var reader = new FileReader();
    reader.onload = async function () {
      var dataURL = reader.result;
      // console.log('DATA URL: ', dataURL);
      await externalFunction(dataURL);
    };
    reader.readAsDataURL(file[0]);
  };
  const externalFunction = async (url) => {
    // console.log('URL: ', url);
    setScreenshotUrl(url);
    // localStorage['editor_background'] = url;
    await localforage.setItem('editor_background', url);
  };
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    setHighlight(true);
  }, []);

  const onDragLeave = useCallback((event) => {
    event.preventDefault();
    setHighlight(false);
  }, []);

  const onFileInput = useCallback((event) => {
    event.preventDefault();
    const files = event.target.files;
    // Process files here
    openFile(files);
    // console.log(files);
  }, []);

  const handleClick = (e) => {
    // console.log(e)
    fileInputRef.current.click();
  };

  const Loading = ({ type, color }) => (
    <div id="loadingContainer">
      <div className="center" style={{ backgroundColor: 'white' }}>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div style={{ color: 'red', fontFamily: 'cursive', fontSize: '20px' }}>
          {' '}
          building...{' '}
        </div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
    </div>
  );
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    // Handle the selected file
    // console.log('Selected file:', file);
  };
  if (screenShotUrl != '' && universalLoginImage != '') {
    if (loadingUl == false) {
      return (
        <div id="configSettingsContainer">
          <div
            id="screenshot-preview-container"
            onDrop={onFileDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            className={`drag-drop-zone ${highlight ? 'highlight' : ''}`}
          >
            <div id="screenshot-image">
              {/* <input type="file" accept="image/*" onChange={handleFileSelect} /> */}
              {highlight ? (
                <img src="./drag-drop.png" id="screenshot_image" />
              ) : (
                <img
                  src={screenShotUrl}
                  id="screenshot_image"
                  onClick={handleClick}
                />
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={onFileInput}
              />
            </div>
          </div>
          <div id="universal-preview-container">
            <div id="ul-image">
              <img src={universalLoginImage} id="ul_image" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="configSettingsContainer">
          <div id="screenshot-preview-container">
            <div id="screenshot-image">
              {!loadingSS && <img src={screenShotUrl} id="screenshot_image" />}
              {loadingSS && <Loading />}
            </div>
          </div>
          <div id="universal-preview-container">
            {/* Universal Login preview
             */}
            <div id="ul-image" src={universalLoginImage}>
              <Loading />
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div id="configSettingsContainer">
        <div id="screenshot-preview-container">
          <div id="screenshot-image">
            {highlight ? (
              <img src="./drag-drop.png" id="screenshot_image" />
            ) : (
              <img src="./generic-background.png" id="screenshot_image" />
            )}
          </div>
        </div>
        <div id="universal-preview-container">
          {/* Universal Login preview
           */}
          <div id="ul-image">
            <img src="./login.png" id="ul_image" />
          </div>
        </div>
      </div>
    );
  }
}

export default ConfigSettings;
