export default class CookieController {
  static #tenant = 'tenant';
  static #cookieKeys = ['domain', 'tenant', 'defaultClientId'];

  constructor() {
    throw new TypeError('You cannot instantiate this class.');
  }

  static async tenantCookieExists() {
    let cookies = document.cookie.split('; ');
    for (let cookie_pair of cookies) {
      cookie_pair = cookie_pair.split('=');
      let key = cookie_pair[0];
      let value = cookie_pair[1];

      if (key === CookieController.#tenant) {
        return true;
      }
    }
    return false;
  }

  static getCookie = (name) => {
    let cookies = document.cookie.split('; ');
    for (let cookiePair of cookies) {
      cookiePair = cookiePair.split('=');
      let key = cookiePair[0];
      let value = cookiePair[1];
      if (key === name) {
        return value;
      }
    }
    return null;
  };

  static getAllCookies() {
    let cookieObj = {};
    for (let key of CookieController.#cookieKeys) {
      cookieObj[key] = CookieController.getCookie(key);
    }
    return cookieObj;
  }

  static deleteAllCookies(domain) {
    for (let key of CookieController.#cookieKeys) {
      CookieController.deleteCookie(key, domain);
    }
  }

  static setCookie(key, value, domain) {
    document.cookie = `${key}=${value}; Path=/; Domain=.${domain}; max-age=9999999999`;
  }

  static deleteCookie(key, domain) {
    document.cookie = `${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=.${domain}`;
  }
}
