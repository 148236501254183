import React, { useState, useContext } from 'react';
import './Carousel.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { AppContext } from '../../../../Helper/Context';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ImArrowRight2, ImArrowLeft2 } from 'react-icons/im';
// let images = [

//     { src: 'https://picsum.photos/500/300?random=1' },
//     { src: 'https://picsum.photos/500/300?random=2' },
//     { src: 'https://picsum.photos/500/300?random=3' },
//     {src: 'https://picsum.photos/500/300?random=4' },
//     { id: 5, src: 'https://picsum.photos/500/300?random=5' },
//     { id: 8, src: 'https://picsum.photos/500/300?random=1' },
//     { id: 9, src: 'https://picsum.photos/500/300?random=2' },
//     { id: 0, src: 'https://picsum.photos/500/300?random=3' },
//     { id: 11, src: 'https://picsum.photos/500/300?random=4' },
//     { id: 12, src: 'https://picsum.photos/500/300?random=5' },
//     { id: 11, src: 'https://picsum.photos/500/300?random=4' },
//     { id: 12, src: 'https://picsum.photos/500/300?random=5' },
//     nextArrow: <SampleNextArrow />,
//     prevArrow: <SamplePrevArrow />
// ];
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ImArrowLeft2
      className={className}
      style={{ ...style, color: 'black', opacity: '.3' }}
      onClick={onClick}
    />
  );
}
function SampleAfterArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ImArrowRight2
      className={className}
      style={{ ...style, color: 'black', opacity: '.3' }}
      onClick={onClick}
    />
  );
}

function SimpleSlider(props) {
  // console.log("Props: ", props.images);
  let { currentFixtures, setCurrentFixtures } = useContext(AppContext);
  const [activeIndex, setActiveIndex] = useState(0);
  let images = [];
  let type = props.type;
  let imagesEle = [];
  if (!props.images) {
    if (type == 'background') {
      images = [
        {
          id: 1,
          src: 'https://www.pixelstalk.net/wp-content/uploads/2016/07/Awesome-Natural-Desktop-Background-wallpaper.jpg',
        },
        { id: 2, src: 'https://cdn.wallpapersafari.com/57/97/NPJjh4.jpg ' },
        { id: 3, src: 'https://wallpaperaccess.com/full/138444.jpg' },
        {
          id: 4,
          src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9PHZMYBMGOyx-ZXcMZcngt5wkqSyQyIiPQA&usqp=CAU',
        },
        {
          id: 5,
          src: 'https://wallpaper-mania.com/wp-content/uploads/2018/09/High_resolution_wallpaper_background_ID_77701894739.jpg',
        },
        {
          id: 8,
          src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUqst98H2IyCG7TVG9fRHjUeiAYxxgjzgsbw&usqp=CAU',
        },
        {
          id: 9,
          src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnRUOQuE0c6R3PIqQtTsZO8hHfJlVLYfjNiw&usqp=CAU',
        },
        {
          id: 0,
          src: 'https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832__480.jpg',
        },
        {
          id: 11,
          src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMoA3lB5wbuCptAP9lcm_KAV5-9xQqoBhFKw&usqp=CAU',
        },
        {
          id: 12,
          src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgyT4_usLuGCYVgZDOLg0WiUL-AoUiN83VsQ&usqp=CAU',
        },
        {
          id: 11,
          src: 'https://images.template.net/wp-content/uploads/2016/01/27110838/Tree-Swing-Awesome-Backgrounds-Download.jpg',
        },
        { id: 12, src: 'https://wallpapercave.com/wp/1aqYrSf.jpg' },
      ];
    } else {
      images = [
        {
          id: 1,
          src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcR6SlYdCpLazqAPG_kjbkQl3MJ5ebucMnww&usqp=CAU',
        },
        {
          id: 2,
          src: 'https://marketplace.canva.com/EAEtLldd3uA/1/0/1600w/canva-blue-orange-abstract-people-solutions-logo-WkQf2dJnlX8.jpg',
        },
        {
          id: 3,
          src: 'https://cdn.shopify.com/shopifycloud/hatchful_web_two/bundles/525858954db2db7a24eb0d1070d316de.png',
        },
        {
          id: 4,
          src: 'https://www.creativefabrica.com/wp-content/uploads/2022/03/13/Agriculture-Logo-Template-Graphics-27093500-1-580x386.jpg',
        },
        {
          id: 5,
          src: 'https://visme.co/blog/wp-content/uploads/2017/08/40-Creative-Logo-Designs-to-Inspire-You-Immanuel-Luthern-Church-1.jpg',
        },
        {
          id: 8,
          src: 'https://logowik.com/content/uploads/images/363_m_letter.jpg',
        },
        {
          id: 9,
          src: 'https://www.logodesign.net/logo/star-shaped-guitar-with-music-notes-396ld.png',
        },
        {
          id: 0,
          src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHWU1k7yRh8FvlrwIQxar_ZH1qG9cXHG7k5A&usqp=CAU',
        },
        {
          id: 11,
          src: 'https://static.free-logo-design.net/uploads/2019/12/free-creative-agency-logo-template.jpg',
        },
        {
          id: 12,
          src: 'https://i.pinimg.com/originals/b8/62/cd/b862cd2f8ba5bb3bb17145d9f3949b36.jpg',
        },
        {
          id: 11,
          src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGXnNdcM_s-us_bkC6RarWuEBQWqCgplOSWQ&usqp=CAU',
        },
        {
          id: 12,
          src: 'https://media.istockphoto.com/id/1140553971/vector/abstract-business-arrow-up-logo-icon-vector-design-template.jpg?s=612x612&w=0&k=20&c=N6bFWaKfmFokGSfTNJhEbYDnF1RplWomcNrOKI65cWU=',
        },
      ];
    }
    imagesEle = images.map((el, idx) => (
      // <div id="slider" style={{backgroundImage: `url(${el.src})`}}>
      <img
        src={el.src}
        key={idx}
        className="imageItem"
        onClick={(e) => testFunction(e)}
      />
      // /* </div> */
    ));
  } else {
    imagesEle = props.images.map((el, idx) => (
      <img
        src={el}
        key={idx}
        className="imageItem"
        onClick={(e) => testFunction(e)}
      />
    ));
  }

  const testFunction = (e) => {
    if (type == 'background') {
      setCurrentFixtures((prevState) => ({
        ...prevState, // Copy the existing state properties
        currentImage: e.target.src, // Update the currentLogo property
      }));
      localStorage['bg_url'] = e.target.src;
    }
    if (type == 'logo') {
      setCurrentFixtures((prevState) => ({
        ...prevState, // Copy the existing state properties
        currentLogo: e.target.src, // Update the currentLogo property
      }));
      localStorage['logo_url'] = e.target.src;
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: false,
    swipe: false,
    nextArrow: <SampleAfterArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div id="slider-container">
      {/* <button onClick={slidePrev}>Slide Back</button>
       <AliceCarousel
        key={activeIndex}
        startIndex={activeIndex}
        onSlideChanged={({ item }) => setActiveIndex(item)}
        items={imagesEle}
        infinite={true}
        autoWidth={true}
        autoHeight={true}
        responsive={responsive}
      />   */}
      {/* <AliceCarousel  key={activeIndex} setActiveIndex items={imagesEle} infinite={true} slideTo={activeIndex}
        onSlideChanged={({ item }) => setActiveIndex(item)} autoWidth={true} autoHeight={true} responsive={responsive}/> */}
      {/* <button onClick={slideNext}>Slide Forward</button> */}
      <Slider {...settings}>{imagesEle}</Slider>;
    </div>
  );
}
export default SimpleSlider;
