import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './StyledMenu';
import { Link } from 'react-router-dom';
import SessionController from '../../controllers/session';
import './menu.css';
const Menu = ({ open }) => {
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  let domain = window.location.hostname;

  return (
    <StyledMenu
      open={open}
      aria-hidden={!isHidden}
      className="menu"
      id="hamburger-menu"
    >
      {/* <a href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">💁🏻‍♂️</span>
        About us
      </a>
      <a href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">💸</span>
        Pricing
        </a>
      <a href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">📩</span>
        Contact
        </a>
      <a> Hello </a>  */}

      <div className="categoryContainer">
        {/* <div className="category">
          <Link to="/finance">
            <div className="box" id="finance">
              {' '}
            </div>
          </Link>
        </div>
 
        <div className="category">
          <Link to="/ecommerce">
            <div className="box" id="ecommerce"></div>
          </Link>
        </div> */}
        <div
          className="category"
          title="Configuration Dashboard - Edit brand settings here."
        >
          <Link to="/settings">
            <div className="box" id="settings"></div>
          </Link>
        </div>
        <div className="category" title="Main View - Demo here.">
          <Link to="/editor">
            <div className="box" id="editor"></div>
          </Link>
        </div>

        <div className="category" title="Navigate to Auth0 Flows.">
          <Link to="/flows">
            <div className="box" id="flows"></div>
          </Link>
        </div>

        {/* <div className="category">
          <Link to="/okta">
            <div className="box" id="okta"></div>
          </Link>
        </div> */}

        <div className="category" title="Clear your cookies.">
          <div
            className="box"
            id="clear-cookies"
            onClick={async () =>
              await SessionController.clearSessionAndCookies(domain)
            }
          ></div>
        </div>
      </div>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
