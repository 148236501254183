import {createContext} from 'react';

export const AppContext = createContext({});

// export const WebsiteUrlContext = createContext({});
// export const ImagesUrlContext = createContext({});
// export const LogosUrlContext = createContext({});

// export const UniversalLoginContext = createContext({});
// export const ScreenShotUrlContext = createContext({});

// logoContext, setLogoContext, text, setText, websiteUrl, 
// websiteUrl, setWebsiteURL, imagesUrl, setImagesUrl,logosUrl, setLogosUrl,
// screenShotUrl, setScreenShotUrl, universalLoginImage, 
// setUniversalLoginImage