import React, { useEffect, useState, useContext } from 'react';
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBCardGroup,
} from 'mdb-react-ui-kit';
import './SettingsCards.css';
import { AiFillApple, AiFillFacebook, AiFillWindows } from 'react-icons/ai';
import { FaGoogle, FaSms } from 'react-icons/fa';
import {
  BsLinkedin,
  BsFillChatSquareTextFill,
  BsFingerprint,
} from 'react-icons/bs';
import { SiAuth0 } from 'react-icons/si';
import { MdAttachEmail } from 'react-icons/md';
import { TbNumbers } from 'react-icons/tb';
import ConfigurationController from '../../../../controllers/configuration';
import CookieController from '../../../../controllers/cookie';
import { AppContext } from '../../../../Helper/Context';

export default function SettingsCards() {
  let {
    selectedSocial,
    setSelectedSocial,
    selectedMFA,
    setSelectedMFA,
    selectedAuthenticationProfile,
    setSelectedAuthenticationProfile,
  } = useContext(AppContext);

  let { tenant } = CookieController.getAllCookies();

  const handleSocialClick = (social) => {
    // console.log('HANDLE: ', social);

    setSelectedSocial((prevState) => ({
      ...prevState,
      [social]: !prevState[social],
    }));
  };
  const handleMFAClick = (mfa) => {
    // console.log('HANDLE: ', mfa);
    setSelectedMFA((prevState) => ({
      ...prevState,
      [mfa]: !prevState[mfa],
    }));
  };
  const handleAuthenticationClick = (profile) => {
    // console.log('HANDLE: ', profile);
    // identifier_first: false,
    // webauthn_platform_first_factor: false
    setSelectedAuthenticationProfile((prevState) => ({
      ...prevState,
      [profile]: !prevState[profile],
    }));
  };

  return (
    <div id="settings-container">
      <div id="card-group">
        <div id="mfa-card" className="settings-card">
          <div className="settings-header">
            <div> Social </div>
          </div>
          <div className="settings-body">
            <div
              onClick={() => handleSocialClick('facebook')}
              className={selectedSocial.facebook ? 'selected' : ''}
              title="Facebook"
            >
              <div>
                {' '}
                <AiFillFacebook id="social-icon" />{' '}
              </div>
            </div>
            <div
              onClick={() => handleSocialClick('apple')}
              className={selectedSocial.apple ? 'selected' : ''}
              title="Apple"
            >
              <div>
                {' '}
                <AiFillApple id="social-icon" />{' '}
              </div>
            </div>
            <div
              onClick={() => handleSocialClick('google-oauth2')}
              className={selectedSocial['google-oauth2'] ? 'selected' : ''}
              title="Google Social"
            >
              <div>
                {' '}
                <FaGoogle id="social-icon" />{' '}
              </div>
            </div>
            <div
              onClick={() => handleSocialClick('linkedin')}
              className={selectedSocial.linkedin ? 'selected' : ''}
              title="LinkedIn"
            >
              <div>
                {' '}
                <BsLinkedin id="social-icon" />{' '}
              </div>
            </div>
            <div
              onClick={() => handleSocialClick('windowslive')}
              className={selectedSocial.windowslive ? 'selected' : ''}
              title="Windows Live"
            >
              <AiFillWindows id="social-icon" />
            </div>
          </div>
        </div>
        <div id="social-card" className="settings-card">
          <div className="settings-header">
            <div> MFA </div>
          </div>
          <div className="settings-body">
            <div
              onClick={() => handleMFAClick('sms')}
              className={selectedMFA.sms ? 'selected' : ''}
              title="SMS"
            >
              <div>
                {' '}
                <BsFillChatSquareTextFill id="social-icon" />{' '}
              </div>
            </div>
            {/* <div onClick ={() => setMFA("Voice")}> Voice Call </div>  */}
            <div
              onClick={() => handleMFAClick('webauthn-platform')}
              className={selectedMFA['webauthn-platform'] ? 'selected' : ''}
              title="WebAuthn"
            >
              <div>
                {' '}
                <BsFingerprint id="social-icon" />{' '}
              </div>
            </div>
            {/* <div
              onClick={() => handleMFAClick('webauthn-roaming')}
              className={selectedMFA['webauthn-roaming'] ? 'selected' : ''}
            >
              {' '}
              Web Authn Roaming{' '}
            </div> */}

            <div
              onClick={() => handleMFAClick('push-notification')}
              className={selectedMFA['push-notification'] ? 'selected' : ''}
              title="Guardian Push"
            >
              {' '}
              <div>
                {' '}
                <SiAuth0 id="social-icon" />{' '}
              </div>
            </div>
            <div
              onClick={() => handleMFAClick('email')}
              className={selectedMFA.email ? 'selected' : ''}
              title="Email"
            >
              <div>
                {' '}
                <MdAttachEmail id="social-icon" />{' '}
              </div>
            </div>
            {/* <div
              onClick={() => handleMFAClick('recovery-code')}
              className={selectedMFA['recovery-code'] ? 'selected' : ''}
            >
              {' '}
              Recovery Code{' '}
            </div> */}
            <div
              onClick={() => handleMFAClick('otp')}
              className={selectedMFA.otp ? 'selected' : ''}
              title="OTP"
            >
              <div>
                {' '}
                <TbNumbers id="social-icon" />{' '}
              </div>
            </div>
          </div>
        </div>
        <div id="identifier-card" className="settings-card">
          <div className="settings-header">
            <div>Authentication Profile</div>
          </div>
          <div className="settings-body">
            <div
              onClick={() => handleAuthenticationClick('identifier_first')}
              className={
                !selectedAuthenticationProfile.identifier_first
                  ? 'selected category-option'
                  : 'category-option'
              }
            >
              <div> Identifier + Password </div>
            </div>
            <div
              onClick={() => handleAuthenticationClick('identifier_first')}
              className={
                selectedAuthenticationProfile.identifier_first
                  ? 'selected category-option'
                  : 'category-option'
              }
            >
              <div> Identifier First </div>
            </div>
            <div
              onClick={() =>
                handleAuthenticationClick('webauthn_platform_first_factor')
              }
              className={
                selectedAuthenticationProfile.webauthn_platform_first_factor
                  ? 'selected category-option'
                  : 'category-option'
              }
            >
              <div>Identifier + Biometrics </div>
            </div>
          </div>
        </div>
        {/* <div id="enterprise-card" className="settings-card">
          <div className="settings-header">
            <div>Enterprise</div>
          </div>
          <div className="settings-body">
            <div
              onClick={() =>
                handleAuthenticationClick('webauthn_platform_first_factor')
              }
              className={
                selectedAuthenticationProfile.webauthn_platform_first_factor
                  ? 'selected category-option'
                  : 'category-option'
              }
            >
              <div>Display as a Button </div>
            </div>
            <div
              onClick={() =>
                handleAuthenticationClick('webauthn_platform_first_factor')
              }
              className={
                selectedAuthenticationProfile.webauthn_platform_first_factor
                  ? 'selected category-option'
                  : 'category-option'
              }
            >
              <div>Enabled </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
