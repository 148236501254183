import TenantController from './tenant';
import CookieController from './cookie';
import localforage from 'localforage';

export default class SessionController {
  static #hostList = window.location.host.split('.');
  static #subdomain = SessionController.#hostList[0];
  constructor() {
    throw TypeError('You cannot instantiate this class');
  }

  static async checkSession() {
    /**
     * Example domains:
     *
     *  1. https://mv-devy.modern-toolkit.com
     *    - Expected when a user launches the app from demo platform
     *    - Domain list should contain 3 items when split by a period (.)
     *    - Check if this subdomain exists
     *        - yes: make sure cookie is set and return user to landing
     *        - no: return the user to /tenant to input tenant name
     *  2. https://modern-toolkit.com
     *    - Expected when a user navigates directly to the site
     *    - Domain list should contain 2 items when split by a period (.)
     *    - Check if tenant cookie exists
     *        - yes: show landing
     *        - no: return user to /tenant to input tenant name
     *
     *
     *
     * */

    // URL has a subdomain
    if (
      SessionController.#hostList.length > 2 &&
      SessionController.#subdomain !== 'www'
    ) {
      let domain = SessionController.#hostList[1];
      let tenantExists = await TenantController.checkTenantExists(
        SessionController.#subdomain
      );
    } else {
      let cookieExists = await CookieController.tenantCookieExists();
      if (!cookieExists) {
        window.location.replace('/tenant');
      }
    }
  }

  static async tenantCookieExistsOnPage() {
    // Check if a cookie already exists
    let cookieExists = await CookieController.tenantCookieExists();
    if (cookieExists) {
      window.location.replace('/');
    }
  }

  static async checkTenantCookie() {
    if (!(await CookieController.tenantCookieExists())) {
      window.location.href = '/tenant';
    }
  }

  static async tenantCookieExists() {
    return await CookieController.tenantCookieExists();
  }

  static async setSessionWithTenant(tenantName) {
    let tenantExists = await TenantController.checkTenantExists(tenantName);
  }

  static setCookiesFromUrlParams() {
    let searchParams = new URL(window.location.href).searchParams;
    let tenant = searchParams.get('tenant');
    let domain = searchParams.get('domain');
    let defaultClientId = searchParams.get('defaultClientId');
    let cookieDomain = new URL(window.location.origin).hostname;
    if (tenant && domain && defaultClientId) {
      CookieController.setCookie('tenant', tenant, cookieDomain);
      CookieController.setCookie('domain', domain, cookieDomain);
      CookieController.setCookie(
        'defaultClientId',
        defaultClientId,
        cookieDomain
      );
      window.history.replaceState({}, '', '/');
    }
  }

  static hasError() {
    let searchParams = new URL(window.location.href).searchParams;
    let error = searchParams.get('error');
    if (error) {
      return true;
    }
  }

  static async clearSessionAndCookies(domain) {
    CookieController.deleteAllCookies(domain);
    await localforage.clear();
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace('/');
  }
}
