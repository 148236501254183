import Utils from './utils';

export default class ProfileController {
  static #baseUrl = Utils.baseUrl;
  constructor() {
    throw new TypeError('No instantiation.');
  }

  static async getUser(tenant, userId) {
    let userUrl = `${
      ProfileController.#baseUrl
    }/api/users?tenant=${tenant}&userId=${userId}`;
    try {
      let getUserRes = await fetch(userUrl);
      return getUserRes;
    } catch (e) {
      return e;
    }
  }

  static async saveUser(tenant, userId, userInfo) {
    let userUrl = `${
      ProfileController.#baseUrl
    }/api/users?tenant=${tenant}&userId=${userId}`;
    try {
      const saveOptions = {
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(userInfo),
      };
      let saveUserRes = await fetch(userUrl, saveOptions);
      return saveUserRes;
    } catch (e) {
      return e;
    }
  }
}
