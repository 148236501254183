import React, { useState, useEffect } from 'react';
import SessionController from '../../controllers/session';

import './tenant.css';

const Tenant = () => {
  let [tenant, setTenant] = useState('');

  useEffect(() => {
    (async () => {
      let cookieExists = await SessionController.tenantCookieExistsOnPage();
    })();
  }, []);
  const grabTenant = async () => {
    // Is this a valid tenant?
    if (tenant) {
      await SessionController.setSessionWithTenant(tenant);
    }
  };
  return (
    <div id="tenant-container">
      <h1 id="tenant-title">
        Welcome to <br /> Modern Toolkit V2
      </h1>
      <div id="tenant-input-container">
        <input
          placeholder="Enter your tenant"
          value={tenant}
          onChange={(e) => setTenant(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              grabTenant();
            }
          }}
          id="tenant-input"
        ></input>
        <button id="tenant-enter-btn" onClick={() => grabTenant()}>
          Enter
        </button>
      </div>
    </div>
  );
};

export default Tenant;
