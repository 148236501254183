import React from 'react';

function Okta(props) {
    return (
        <div id='flowsContainer'>
             <iframe id="flowsFrame" src='https://explorer.auth0.com/' />
        </div>
    );
}

export default Okta;