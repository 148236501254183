import React, { useState, useRef, useEffect } from 'react';
import './generalForm.css';

import { Auth0Client } from '@auth0/auth0-spa-js';

import jwt_decode from 'jwt-decode';
import CookieController from '../../controllers/cookie';
import ProfileController from '../../controllers/profile';

function GeneralForm(props) {
  let { domain, defaultClientId, tenant } = CookieController.getAllCookies();

  const shouldRedirect = useRef(true);

  let [user, setUser] = useState(
    window.localStorage.getItem('logged_in_user_id') || null
  );

  let [userId, setUserId] = useState(
    window.localStorage.getItem('logged_in_user_id') || null
  );

  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [gender, setGender] = useState('m');
  let [address, setAddress] = useState('');
  let [city, setCity] = useState('');
  let [state, setState] = useState('ca');
  let [dob, setDOB] = useState('1970-01-02');
  let [subscription, setSubscription] = useState('premium');

  let a0 = new Auth0Client({
    domain,
    clientId: defaultClientId,
    authorizationParams: {
      redirect_uri: `${window.location.origin}/profile/general`,
    },
  });

  useEffect(() => {
    (async () => {
      if (user && typeof user === 'string') {
        // Make user api call for this id and reset state
        const options = {
          method: 'GET',
        };

        let user_res = await ProfileController.getUser(tenant, user);
        user_res = await user_res.json();
        console.log(user_res);
        setUser(user_res.user_metadata);

        let temp_user = user_res.user_metadata;
        setFirstName(temp_user.firstName);
        setLastName(temp_user.lastName);
        setDOB(temp_user.dob);
        setGender(temp_user.gender);
        setAddress(temp_user.address);
        setCity(temp_user.city);
        setState(temp_user.state);
        setSubscription(temp_user.subscription);
      }
    })();
    if (shouldRedirect.current) {
      shouldRedirect.current = false;
      (async () => {
        const query = window.location.search;
        const shouldParseResult =
          query.includes('code=') && query.includes('state=');

        if (shouldParseResult) {
          const res = await a0.handleRedirectCallback();
          let user_info = await a0.getUser();
          const id_token_claims = await a0.getIdTokenClaims();
          const id_token_raw = id_token_claims.__raw;
          delete id_token_claims.__raw;
          window.localStorage.setItem(
            'id_token_claims',
            JSON.stringify(id_token_claims)
          );
          window.localStorage.setItem('id_token_raw', id_token_raw);

          const access_token_raw = await a0.getTokenSilently();
          const access_token_claims = jwt_decode(access_token_raw);
          console.log(access_token_claims);
          window.localStorage.setItem('access_token_raw', access_token_raw);
          window.localStorage.setItem(
            'access_token_claims',
            JSON.stringify(access_token_claims)
          );

          setUser(user_info.sub);
          setUserId(user_info.sub);
          window.localStorage.setItem('logged_in_user_id', user_info.sub);

          window.history.replaceState({}, '', '/profile/general');
        }
      })();
    }
  }, [user, firstName, lastName, dob, gender, address, city, state]);

  const saveUser = async () => {
    if (userId && tenant) {
      const userInfo = {
        firstName,
        lastName,
        dob,
        gender,
        address,
        state,
        city,
        subscription,
      };
      let res = await ProfileController.saveUser(tenant, userId, userInfo);

      if (res) {
        alert('Successfully saved profile');
      }
      return res;
    }
  };

  return (
    <div id="general-form-container">
      <div id="form-header">General Information</div>
      <div id="general-name">
        <div id="input-group">
          <label for="fname"> First Name </label>
          <input
            type="text"
            placeholder="Enter your first name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div id="input-group">
          <label for="fname"> Last Name </label>
          <input
            type="text"
            placeholder="Enter your last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div id="general-demographic">
        <div id="input-group">
          <label for="birthday"> Date of Birth</label>
          <input
            type="date"
            value={dob}
            onChange={(e) => setDOB(e.target.value)}
          />
        </div>
        <div id="input-group">
          <label for="gender"> Gender </label>
          <select
            name="cars"
            id="gender-dropdown"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="m">Male</option>
            <option value="f">Female</option>
            <option value="pf">Prefer not to say</option>
          </select>
        </div>
      </div>
      <div id="general-address">
        <div id="input-group">
          <label for="address"> Address </label>
          <input
            type="text"
            placeholder="Please enter your address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div id="input-group-city-state">
          <div>
            <label for="address"> City </label>
            <input
              type="text"
              placeholder="Please enter your city name"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div>
            <label for="address"> State </label>
            <select
              name="cars"
              id="gender-dropdown"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option value="ca">California</option>
              <option value="tx">Texas</option>
              <option value="wa">Washington</option>
              <option value="ny">New York</option>
            </select>
          </div>
        </div>
      </div>
      <div id="general-selection">
        <div id="input-group">
          <label for="subscription"> Account Subscription </label>
          <select
            name="subscription"
            id="gender-dropdown"
            value={subscription}
            onChange={(e) => setSubscription(e.target.value)}
          >
            <option value="premium">Premium</option>
            <option value="standard">Standard</option>
            <option value="none">No Subscription</option>
          </select>
        </div>
      </div>

      <div id="save-form">
        <button> Edit </button>
        <button onClick={saveUser}> Save </button>
      </div>
    </div>
  );
}

export default GeneralForm;
