import axios from 'axios';
import Utils from './utils';

export default class BrandController {
  static #baseUrl = Utils.baseUrl;
  constructor() {
    throw new TypeError("Can't instantiate");
  }

  static async setBrand(tenant, brand) {
    const brandUrl = `${BrandController.#baseUrl}/api/brand?tenant=${tenant}`;
    const setOptions = {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ brand }),
    };
    let res = await fetch(brandUrl, setOptions);
    res = await res.json();
  }

  static async getImageFromThumioFor(inputUrl) {
    try {
      const thumioUrl = `https://image.thum.io/get/68300/width/1920/crop/1080/noanimate/viewportWidth/1920/wait/1/maxAge/1/auth/68449-mtkv2/${inputUrl}`;
      let imageRes = await axios.get(thumioUrl, { responseType: 'blob' });
      return imageRes;
    } catch (e) {
      return e;
    }
  }
}
