import React, { useState, useEffect, useContext } from 'react';
import './editor.css';

import { Auth0Client } from '@auth0/auth0-spa-js';

import CookieController from '../../controllers/cookie';
import SessionController from '../../controllers/session';
import localforage from 'localforage';

function Editor(props) {
  const [image, setImage] = useState('');
  useEffect(() => {
    (async () => {
      let sessionExists = await SessionController.checkSession();
      if (!(await localforage.getItem('editor_background'))) {
        window.location.replace('/settings');
      } else {
        setImage(await localforage.getItem('editor_background'));
      }
    })();
  }, []);

  let menu_element = document.querySelector('#styled-burger');
  let initial_search_container = document.querySelector('#search-box');
  let initial_search_button = document.querySelector('#searchBox');
  let [iframeSrc, setIframeSrc] = useState('');
  let [doneBranding, setDoneBranding] = useState(false);

  let { defaultClientId, domain } = CookieController.getAllCookies();
  if (initial_search_button) {
    initial_search_container.addEventListener('click', function () {
      console.log('CLICKED');
      initial_search_button.style.animation = 'none';
    });
  }
  if (menu_element) {
    menu_element.style.animation = 'none';
    menu_element.style.opacity = '.07';
    menu_element.addEventListener('mouseover', () => {
      menu_element.style.opacity = '1';
    });
    menu_element.addEventListener('mouseout', () => {
      menu_element.style.opacity = '.07';
    });
  }

  const [input, setInput] = useState('https://www.');

  const openUniversalLogin = async () => {
    let a0 = new Auth0Client({
      domain,
      clientId: defaultClientId,
      authorizationParams: {
        redirect_uri: `${window.location.origin}/profile/general`,
      },
    });

    await a0.loginWithRedirect();
  };

  return (
    <div id="customContainer" style={{ backgroundImage: `url(${image})` }}>
      <div id="loginButton" onClick={openUniversalLogin}></div>
    </div>
  );
}

export default Editor;
