import Utils from './utils';

export default class ConfigurationController {
  static #baseUrl = Utils.baseUrl;
  constructor() {
    throw TypeError("Don't instantiate this class.");
  }

  static async getCurrentMFA(tenant) {
    const mfaUrl = `${
      ConfigurationController.#baseUrl
    }/api/mfa?tenant=${tenant}`;
    try {
      let mfaOptions = await fetch(mfaUrl, { credentials: 'include' });
      mfaOptions = await mfaOptions.json();
      return mfaOptions;
    } catch (e) {
      return e;
    }
  }

  static async setMFA(tenant, mfaOptions) {
    const mfaUrl = `${
      ConfigurationController.#baseUrl
    }/api/mfa?tenant=${tenant}`;
    const setOptions = {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mfaOptions),
    };
    try {
      let setMfaRes = await fetch(mfaUrl, setOptions);
      return setMfaRes;
    } catch (e) {
      return e;
    }
  }

  static async getCurrentSocial(tenant) {
    const socialUrl = `${
      ConfigurationController.#baseUrl
    }/api/connections?tenant=${tenant}`;
    try {
      let socialOptions = await fetch(socialUrl, { credentials: 'include' });
      socialOptions = await socialOptions.json();
      return socialOptions;
    } catch (e) {
      return e;
    }
  }

  static async setSocial(tenant, socialOptions) {
    const socialUrl = `${
      ConfigurationController.#baseUrl
    }/api/connections?tenant=${tenant}`;
    const setOptions = {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(socialOptions),
    };
    try {
      let setSocialRes = await fetch(socialUrl, setOptions);
      setSocialRes = await setSocialRes.json();
      return setSocialRes;
    } catch (e) {
      return e;
    }
  }

  static async getAuthenticationProfile(tenant) {
    const authProfileUrl = `${
      ConfigurationController.#baseUrl
    }/api/authProfile?tenant=${tenant}`;

    try {
      let authProfileOptions = await fetch(authProfileUrl);
      authProfileOptions = await authProfileOptions.json();
      return authProfileOptions;
    } catch (e) {
      return e;
    }
  }

  static async setAuthenticationProfile(tenant, authProfile) {
    const authProfileUrl = `${
      ConfigurationController.#baseUrl
    }/api/authProfile?tenant=${tenant}`;
    const setOptions = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(authProfile),
    };
    try {
      let setAuthProfileRes = await fetch(authProfileUrl, setOptions);
      return setAuthProfileRes;
    } catch (e) {
      return e;
    }
  }

  static async setOrganizationPrompt(tenant, enabled) {
    const toggleOrgUrl = `${
      ConfigurationController.#baseUrl
    }/api/clients?tenant=${tenant}`;
    // This will be a boolean, enabled: true or false
    let data = {
      enabled,
    };
    const toggleOptions = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    try {
      let toggleOrgRes = await fetch(toggleOrgUrl, toggleOptions);
      return toggleOrgRes;
    } catch (e) {
      return e;
    }
  }

  static async getOrganizationPrompt(tenant) {
    const toggleOrgUrl = `${
      ConfigurationController.#baseUrl
    }/api/clients?tenant=${tenant}`;
    const getOptions = {
      method: 'GET',
    };
    try {
      let orgPromptRes = await fetch(toggleOrgUrl, getOptions);
      orgPromptRes = await orgPromptRes.json();
      let res =
        'organization_usage' in orgPromptRes
          ? orgPromptRes.organization_usage
          : '';
      return res === 'require';
    } catch (e) {
      throw e;
    }
  }

  static async getCurrentEnterprise(tenant) {
    const entUrl = `${
      ConfigurationController.#baseUrl
    }/api/entConnections?tenant=${tenant}`;
    try {
      let entOptions = await fetch(entUrl, { credentials: 'include' });
      entOptions = await entOptions.json();
      return entOptions;
    } catch (e) {
      return e;
    }
  }

  static async setCurrentEnterprise(tenant, enabled, show_as_button) {
    const entUrl = `${
      ConfigurationController.#baseUrl
    }/api/entConnections?tenant=${tenant}`;
    let data = {
      enabled,
      show_as_button,
    };
    const patchOptions = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    try {
      let entOptions = await fetch(entUrl, patchOptions);
      entOptions = await entOptions.json();
      return entOptions;
    } catch (e) {
      return e;
    }
  }
}
