import Utils from './utils';

export default class TenantController {
  static #baseUrl = Utils.baseUrl;

  constructor() {
    throw new TypeError('This class cannot be instantiated');
  }

  static async checkTenantExists(tenantName) {
    let tenantUrl = `${
      TenantController.#baseUrl
    }/api/tenant/exists?tenant=${tenantName}&redirectUri=${
      window.location.origin
    }`;
    window.location.replace(tenantUrl);
  }
}
